import React from 'react'
import Slider from 'react-slick'
import { graphql, useStaticQuery } from 'gatsby'
import { CarouselContainer, CardImage, Header, Text, ImageContainer, Disclaimer } from './styles'

const GreetingCard = () => {
  const { cardOne, cardTwo, cardThree, cardFour, cardFive, cardSix } = useStaticQuery(graphql`
    query GreetingCardQuery {
      cardOne: file(relativePath: { eq: "card-one.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      cardTwo: file(relativePath: { eq: "card-two.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      cardThree: file(relativePath: { eq: "card-three.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      cardFour: file(relativePath: { eq: "card-four.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      cardFive: file(relativePath: { eq: "card-five.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      cardSix: file(relativePath: { eq: "card-six.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `)
  const images = [cardOne, cardTwo, cardThree, cardFour, cardFive, cardSix]
  return (
    <CarouselContainer>
      <Slider
        infinite
        speed={500}
        slidesToShow={6}
        slidesToScroll={1}
        responsive={[
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 1,
              centerMode: false,
              arrows: false,
            },
          },
        ]}
      >
        {images.map((img, index) => (
          <ImageContainer key={index}>
            <CardImage image={images[index].childImageSharp.gatsbyImageData} alt="Greeting Card" />
          </ImageContainer>
        ))}
      </Slider>
      <Header>Send custom digital greeting cards with Skylight Plus</Header>
      <Text>
        Magically beam cards to your loved ones. Take your favorite memories from your Skylight
        Frame and easily turn them into beautiful digital greeting cards for occasions big and
        small.
      </Text>
      <Disclaimer>
        Digital Greeting cards (e-cards) are available with a Skylight Plus subscription ($39/year)
      </Disclaimer>
    </CarouselContainer>
  )
}

export default GreetingCard
