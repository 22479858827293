import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import Hero from '../components/Hero'
import SEO from '../components/seo'
import FramePress from '../components/FramePress'
import CalendarTestimonials from '../components/CalendarTestimonials'

import FAQs from '../components/FAQs'
import Guarantee from '../components/Guarantee'
import FreeReturns from '../components/FreeReturns'
import GreetingCard from '../components/GreetingCard'
import { FRAME_FEATURES } from '../components/FrameFeatures/constants'
import { HideOverflow } from '../utils/styles/global-styles'
import Analytics from '../utils/Analytics'
import FrameFeatures from '../components/FrameFeatures'

class FramePage extends React.Component {
  componentDidMount() {
    Analytics.track('Viewed Greeting Card Landing Page')
  }

  render() {
    const { t } = this.props
    return (
      <>
        <SEO
          title={t('Digital Picture Frames | Skylight Frame')}
          description={t(
            'Skylight is a WiFi-enabled, touchscreen digital frame that lets you easily send pictures to your loved ones by email or mobile app.'
          )}
          path="/frame-greeting-card/"
        />
        <Helmet>
          <meta charset="UTF-8" />
          <link rel="canonical" href="https://www.skylightframe.com/frame" />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
          />
        </Helmet>
        <HideOverflow>
          <Hero page="greetings" title="The perfect gift just got better" />
          <FramePress />
          <GreetingCard />
          <Guarantee />
          <CalendarTestimonials page="frame" title="Families Rave About Skylight Frame" />
          <FrameFeatures features={FRAME_FEATURES} page="greetings" />
          <FreeReturns />
          <FAQs page="frame" />
        </HideOverflow>
      </>
    )
  }
}

FramePage.propTypes = {
  t: PropTypes.func,
}

export default withTranslation()(FramePage)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "frame"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
