import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { GatsbyImage } from 'gatsby-plugin-image'

export const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.orange};
  padding: 40px 0px;
  .slick-slider {
    max-width: 1300px;
    margin: auto;
  }
  @media (max-width: 1300px) {
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    .slick-slider {
      width: 95%;
      margin-left: 5%;
    }
  }
`
export const CardImage = styled(GatsbyImage)`
  width: 200px;
  @media (max-width: 1300px) {
    max-width: 90%;
  }
`
export const ImageContainer = styled.div`
  display: flex !important;
  justify-content: center;
  @media (max-width: ${breakpoints.s}px) {
    margin-left: 50%;
  }
`
export const Header = styled.h2`
  text-align: center;
  font-size: 26px;
  max-width: 90%;
  margin: 40px auto 8px;
  @media (max-width: ${breakpoints.m}px) {
    margin: 20px auto 9px;
  }
  @media (max-width: 400px) {
    font-size: 23px;
  }
`
export const Text = styled.p`
  text-align: center;
  font-family: 'FilsonProBook';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  max-width: 90%;
  margin: auto;
  max-width: 720px;
  @media (max-width: ${breakpoints.xl}px) {
    max-width: 90%;
  }
`
export const Disclaimer = styled.p`
  font-size: 12px;
  text-align: center;
  font-family: 'FilsonProRegularItalic';
  margin: 20px auto 0px;
  max-width: 90%;
`
